html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
  Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
  Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
  font-size: 14px;
}
body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

#nprogress {
  z-index: 1600;
  position: relative;
}

#nprogress div.spinner {
  display: none;
}
